import React from 'react';
import { HelmetWrapper, basicHelmetGenerator } from 'utility/DefaultMeta';
import { renderRoutes } from 'react-router-config';
import { routeShape } from '../../propTypesObjects';

function SearchResults({ route }) {
  return (
    <div>
      <HelmetWrapper
        metadata={basicHelmetGenerator({
          title: 'PipeSearch - Search Products',
        })}
      />
      {renderRoutes(route?.routes)}
    </div>
  );
}

SearchResults.defaultProps = {
  route: undefined,
};

SearchResults.propTypes = {
  route: routeShape,
};

export default SearchResults;
