import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useBranch } from 'baobab-react/hooks';
import { Row, Col } from '@zendeskgarden/react-grid';
import { resetActiveRfq } from 'state/requestForQuote/actions';
import { variables } from 'theme/variables';
import { locationShape } from 'propTypesObjects';
import GlobalProductsCustomInquire from './components/GlobalProductsCustomInquire/GlobalProductsCustomInquire';
import GlobalProductInquireOverlay from './components/GlobalProductInquireOverlay/GlobalProductInquireOverlay';

const { public_app_max_width: publicAppMaxWidth } = variables;

function GlobalProductsCustomInquireWrapper({
  location,
  activeInPageOverlayView,
  title,
  listingId,
  product,
  productId,
  view,
  disableOverlay,
}) {
  const [addCustomItem, setAddCustomItem] = useState();
  const {
    cipher,
    token,
    need_date: needDate,
    place_id: placeId,
    quantity,
    quantity_uom: quantityUom,
    action,
    object_id: objectId,
    content_type_id: contentTypeId,
  } = location?.query || {};
  const inquiryData = {
    needDate,
    placeId,
    quantity,
    quantityUom,
  };

  const overlayTriggeredFromParent = activeInPageOverlayView?.id || ''; // listing and product pages
  let defaultOverlay = cipher && token && action;
  if (defaultOverlay) {
    defaultOverlay =
      Number(action) === 1 ? { type: 'wishlist' } : { type: 'inquire' };
  } else {
    defaultOverlay = false;
  }
  const [currentActiveInPageOverlayView, setInPageOverlay] =
    useState(defaultOverlay);

  const { user } = useBranch({
    user: ['authentication', 'session'],
  });

  function onInquire({ criteria } = {}) {
    setInPageOverlay({ type: 'inquire', criteria });
  }

  const onDoneInquire = useCallback(() => {
    setInPageOverlay(false);
    setAddCustomItem('complete');
    resetActiveRfq();
  }, []);

  useEffect(() => {
    if (overlayTriggeredFromParent) {
      setInPageOverlay(activeInPageOverlayView);
    }
  }, [activeInPageOverlayView, overlayTriggeredFromParent]);

  return (
    <Row>
      <Col>
        <GlobalProductsCustomInquire
          view={view}
          style={{
            minWidth: '100px',
            maxWidth: publicAppMaxWidth,
            width: '100%',
          }}
          saveInterceptFunction={(criteria) => {
            onInquire({ criteria });
          }}
          addCustomItem={addCustomItem}
          setAddCustomItem={setAddCustomItem}
          title={title}
        />
        {
          // We only want to render this once per page. So if this component is rendered  multiple times on one page make sure to disable it on all but one instance
          !disableOverlay ? (
            <GlobalProductInquireOverlay
              cipher={cipher}
              token={token}
              objectId={objectId}
              inquiryData={inquiryData}
              contentTypeId={contentTypeId}
              setInPageOverlay={setInPageOverlay}
              activeInPageOverlayView={currentActiveInPageOverlayView}
              onDoneInquire={onDoneInquire}
              user={user}
              location={location}
              product={product}
              listingId={listingId}
              productId={productId}
            />
          ) : null
        }
      </Col>
    </Row>
  );
}

GlobalProductsCustomInquireWrapper.defaultProps = {
  view: undefined,
  title: undefined,
  location: undefined,
  activeInPageOverlayView: undefined,
  listingId: undefined,
  product: undefined,
  productId: undefined,
  disableOverlay: undefined,
};

GlobalProductsCustomInquireWrapper.propTypes = {
  location: locationShape,
  activeInPageOverlayView: PropTypes.shape({
    id: PropTypes.string,
  }),
  listingId: PropTypes.string,
  productId: PropTypes.string,
  product: PropTypes.shape({}),
  title: PropTypes.string,
  view: PropTypes.string,
  disableOverlay: PropTypes.bool,
};

export default GlobalProductsCustomInquireWrapper;
