import React, { memo, useState } from 'react';
import Flex from 'styled-flex-component';
import { Tag } from '@zendeskgarden/react-tags';
import isIe11 from 'utility/isIe11';
import { trackClick } from 'utility/analytics';
import numeral from 'numeral';
import { Button } from 'theme/Button';
import { Link } from 'react-router-dom';
import { SM, LG } from '@zendeskgarden/react-typography';
import { PropTypes } from 'prop-types';
import {
  ProductImageWrapper,
  Wrapper,
  ItemContainer,
  ProductImage,
  ProductContent,
  ItemContainerWrapper,
} from './ListingGridItem.styles';

function ListingGridItem({
  style,
  title,
  view,
  id,
  image_url: imageUrl,
  quantity,
  condition_name: conditionName,
  brand_name: brandName,
  url,
  slug,
  fromInternal,
}) {
  const [isIe] = useState(isIe11());
  return (
    <Wrapper
      style={style || {}}
      className="wrap"
      onClick={() => {
        trackClick('viewListingSiderItem', 'card', view || 'listing', {
          title,
          id,
        });
      }}
    >
      <ItemContainerWrapper>
        <ItemContainer style={{ paddingBottom: '0px' }}>
          <ProductImageWrapper>
            <ProductImage
              className={isIe || !imageUrl ? 'max' : 'full'}
              lazy="true"
              noImage={!imageUrl}
              src={imageUrl || '/images/logo-grey-pipe.svg'}
              alt={title}
            />
          </ProductImageWrapper>
          <ProductContent>
            <Flex justifyBetween alignCenter>
              {quantity ? (
                <SM light navy>
                  {numeral(quantity).format('0,0')} ft.
                </SM>
              ) : null}

              {conditionName ? (
                <Tag
                  blue={conditionName === 'New'}
                  slate={conditionName === 'Unused'}
                  pill
                >
                  <SM xs>{conditionName}</SM>
                </Tag>
              ) : null}
            </Flex>
            <LG paddingTopSm navy black style={{ lineHeight: '25px' }}>
              {title}
            </LG>
            {brandName ? (
              <SM xs uppercase paddingTopSm slate light>
                PipeFacts: {brandName}
              </SM>
            ) : null}
          </ProductContent>

          <Link
            to={{
              pathname: url || `/l/${slug || 'listing'}/${id}`,
              state: {
                fromInternal,
              },
            }}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: -55,
              left: 0,
            }}
          />
        </ItemContainer>
        <Button
          bold
          style={{
            borderRadius: '8px',
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            margin: '0',
            display: 'block',
            border: 'none',
            marginTop: '20px',
            color: '#0268ef',
            fontSize: '12px',
            pointerEvents: 'none',
          }}
        >
          View Item
        </Button>
      </ItemContainerWrapper>
    </Wrapper>
  );
}

ListingGridItem.defaultProps = {
  style: undefined,
  title: undefined,
  view: undefined,
  id: undefined,
  image_url: undefined,
  quantity: undefined,
  condition_name: undefined,
  brand_name: undefined,
  url: undefined,
  slug: undefined,
  fromInternal: undefined,
};

ListingGridItem.propTypes = {
  style: PropTypes.shape({}),
  title: PropTypes.string,
  view: PropTypes.string,
  id: PropTypes.number,
  image_url: PropTypes.string,
  quantity: PropTypes.number,
  condition_name: PropTypes.string,
  brand_name: PropTypes.string,
  url: PropTypes.string,
  slug: PropTypes.string,
  fromInternal: PropTypes.bool,
};

export function ListingGridItemUpgrade({ style, title, view, id }) {
  return (
    <Wrapper
      style={style || {}}
      className="wrap"
      onClick={() => {
        trackClick('viewListingSiderItemUpgrade', 'card', view || 'listing', {
          title,
          id,
        });
      }}
    >
      <ItemContainerWrapper>
        <ItemContainer
          style={{
            paddingBottom: '0px',
            background: 'rgba(22, 104, 239, 0.28)',
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LG scaled center navy black>
            Want Access To All Listings?
          </LG>
          <LG paddingTopSm navy link blue>
            Register Now
          </LG>
        </ItemContainer>
        <Button
          style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          bold
          primary
        >
          View All Listings
        </Button>
      </ItemContainerWrapper>
    </Wrapper>
  );
}

ListingGridItemUpgrade.defaultProps = {
  style: undefined,
  title: undefined,
  view: undefined,
  id: undefined,
};

ListingGridItemUpgrade.propTypes = {
  style: PropTypes.shape({}),
  title: PropTypes.string,
  view: PropTypes.string,
  id: PropTypes.number,
};

export function ListingGridSubmitRfq({ style }) {
  return (
    <Wrapper
      style={style || {}}
      className="wrap"
      onClick={() => {
        trackClick(
          'viewListingSiderItemSubmitRfq',
          'card',
          'ExclusiveListings'
        );
      }}
    >
      <ItemContainerWrapper>
        <ItemContainer
          style={{
            paddingBottom: '0px',
            background: 'rgba(22, 104, 239, 0.28)',
            padding: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LG scaled center navy black>
            Dont See What You Are Looking For?
          </LG>
          <LG paddingTopSm navy link blue>
            Submit An RFQ
          </LG>
        </ItemContainer>
        <Button
          style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          bold
          primary
        >
          Submit An RFQ
        </Button>
      </ItemContainerWrapper>
    </Wrapper>
  );
}

ListingGridSubmitRfq.defaultProps = {
  style: undefined,
};

ListingGridSubmitRfq.propTypes = {
  style: PropTypes.shape({}),
};

export default memo(ListingGridItem, (pp, np) => pp.id === np.id);
