import React, { useState, useEffect } from 'react';
import { trackClick } from 'utility/analytics';
import InPageOverlay from 'components/InPageOverlay/InPageOverlay';
import { useBranch } from 'baobab-react/hooks';
import PageViewWrapper from 'higherOrder/PageViewWrapper';
import { AuthWrapper } from 'routes/SubmitSellersApplication/SubmitSellersApplication.styles.js';

import LoginFormWrapper from 'components/BasicForms/LoginForm';
import { AuthFormWrapper } from 'routes/CreateAccount/components/CreateAccountForm/CreateAccountForm.js';
import { PropTypes } from 'prop-types';
import {
  historyShape,
  locationShape,
  matchShape,
  routeShape,
} from '../../propTypesObjects';

function AccountModal({
  history,
  isDefaultOpen,
  location,
  match,
  route,
  staticContext,
  trackCurrentPage,
  onAuthentication,
  onClose,
  title,
  cta,
}) {
  const [login, setLogin] = useState(false);
  const [inPageOverlayViewOpen, setInPageOverlay] = useState(false);

  const { email } = useBranch({
    email: ['authentication', 'session', 'email'],
  });
  useEffect(() => {
    if (email) {
      if (inPageOverlayViewOpen && onAuthentication) {
        onAuthentication();
      } else {
        setInPageOverlay(false);
      }
    } else if (inPageOverlayViewOpen && isDefaultOpen === false) {
      setInPageOverlay(false);
    } else if (isDefaultOpen) {
      setInPageOverlay(true);
    }
  }, [isDefaultOpen, email, inPageOverlayViewOpen, onAuthentication]);

  function cancel() {
    trackClick('closeRoutedModal', 'x', 'AccountModal');
    setInPageOverlay(false);
    if (onClose) {
      onClose();
    } else {
      history.goBack();
    }
  }

  return (
    <InPageOverlay
      title={title || 'How can we contact you?'}
      showHeader
      disableBackgroundClick
      portal
      disableScroll
      background="rgba(0, 0, 0, 0.82)"
      onClose={() => cancel()}
      open={inPageOverlayViewOpen}
    >
      <div style={{ padding: '0 29px' }}>
        <AuthWrapper style={{ paddingBottom: '30px', maxWidth: '400px' }}>
          {login ? (
            <div style={{ paddingTop: 50 }}>
              <LoginFormWrapper
                onSuccess={() => {
                  setInPageOverlay(false);
                }}
                onClickRegister={() => {
                  setLogin(false);
                }}
                history={history}
                isDefaultOpen={isDefaultOpen}
                location={location}
                match={match}
                route={route}
                staticContext={staticContext}
                trackCurrentPage={trackCurrentPage}
                onAuthentication={onAuthentication}
                onClose={onClose}
                title={title}
                cta={cta}
              />
            </div>
          ) : (
            <AuthFormWrapper
              onSuccess={() => {
                setInPageOverlay(false);
              }}
              onClickLogin={() => {
                setLogin(true);
              }}
              ignoreMobile
              cta={cta || 'Continue To Add Item'}
              history={history}
              isDefaultOpen={isDefaultOpen}
              location={location}
              match={match}
              route={route}
              staticContext={staticContext}
              trackCurrentPage={trackCurrentPage}
              onAuthentication={onAuthentication}
              onClose={onClose}
              title={title}
            />
          )}
        </AuthWrapper>
      </div>
    </InPageOverlay>
  );
}

AccountModal.defaultProps = {
  history: undefined,
  isDefaultOpen: undefined,
  location: undefined,
  match: undefined,
  route: undefined,
  staticContext: undefined,
  trackCurrentPage: undefined,
  onAuthentication: undefined,
  onClose: undefined,
  title: undefined,
  cta: undefined,
};

AccountModal.propTypes = {
  history: historyShape,
  isDefaultOpen: PropTypes.bool,
  location: locationShape,
  match: matchShape,
  route: routeShape,
  staticContext: PropTypes.shape({}),
  trackCurrentPage: PropTypes.func,
  onAuthentication: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  cta: PropTypes.string,
};

export default PageViewWrapper(AccountModal, { pageName: 'AccountModal' });
